import {
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'

const styles = makeStyles(() => ({
  card: {
    margin: 10,
  },
  cardHeader: {
    whiteSpace: 'nowrap',
    '@media screen and (orientation: portrait)': {
      fontSize: 'calc(12px + (24 - 12)*(100vw - 350px)/(1920 - 350))',
    },
    '@media screen and (orientation: landscape)': {
      fontSize: 'calc(10px + (20 - 12)*(100vw - 350px)/(1920 - 350))',
    },
  },
  schemeElement: {
    width: '20%',
    paddingTop: '10%',
  },
}))

const SchemeCard = ({
  scheme,
  handleSchemeSelect,
  currentDesign,
  viewElements,
}) => {
  const classes = styles()
  const selected = currentDesign && scheme.id === currentDesign.schemeId
  return (
    <Card
      className={classes.card}
      style={{
        backgroundColor: selected ? '#cccccc' : 'white',
      }}
      onClick={() => handleSchemeSelect(scheme)}
    >
      <CardActionArea>
        <CardContent>
          <Typography className={classes.cardHeader} variant="h6">
            {scheme.name}
          </Typography>
          <div style={{ display: 'flex', flexFlow: 'row', flexWrap: 'wrap' }}>
            {scheme.scheme_elements
              .filter((schemeElement) =>
                viewElements?.includes(schemeElement.material.id)
              )
              .filter(
                (schemeElement, index, self) =>
                  index ===
                  self.findIndex(
                    (element) => element.color.hex === schemeElement.color.hex
                  )
              )
              .map((schemeElement) => (
                <div
                  key={schemeElement.id}
                  className={classes.schemeElement}
                  style={{
                    backgroundColor: `#${schemeElement.color.hex}`,
                  }}
                />
              ))}
          </div>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

SchemeCard.propTypes = {
  scheme: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    scheme_elements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        element_id: PropTypes.number,
        color: PropTypes.shape({
          id: PropTypes.number,
          hex: PropTypes.string,
        }),
        material: PropTypes.shape({
          id: PropTypes.number,
        }),
      })
    ),
  }).isRequired,
  handleSchemeSelect: PropTypes.func.isRequired,
  currentDesign: PropTypes.shape({
    customSchemes: PropTypes.arrayOf(
      PropTypes.shape({
        schemeId: PropTypes.number,
        materials: PropTypes.arrayOf(
          PropTypes.shape({
            colorId: PropTypes.number,
            colorPaletteId: PropTypes.number,
            elementId: PropTypes.number,
            materialId: PropTypes.number,
          })
        ),
      })
    ),
    schemeId: PropTypes.number,
    viewId: PropTypes.number,
  }),
  viewElements: PropTypes.arrayOf(PropTypes.number),
}

SchemeCard.defaultProps = {
  currentDesign: {},
  viewElements: [],
}

export default SchemeCard
