import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { AutoSizer } from 'react-virtualized'
import ExteriorImage from '@anewgo/exterior-image'
import { Button, makeStyles } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import {
  completeColorIdentifier,
  createElevation,
  getCustomColorsForSelectedScheme,
  getCustomSchemeFromSelection,
  islistOrderGreater,
} from '../../../../utils/helper'
import { BASE_IMAGE_URL } from '../../../constants/Paths'

const styles = makeStyles({
  page: {
    border: '3px solid whitesmoke',
    borderRadius: '10px',
    paddingLeft: '2vw',
    marginLeft: '5px',
    marginTop: '5px',
    width: '98vw',
    paddingBottom: '5px',
    height: '95vh',
    display: 'grid',
    gridGap: '25px',
    gridTemplateRows: '.5fr 10fr auto auto',
    gridTemplateAreas: '"header" "photo" "sider2" "buttonArea"',
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    gridArea: 'header',
    fontSize: 35,
    marginTop: 10,
    justifyContent: 'center',
    '@media (max-width: 650px)': {
      fontSize: '15px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '15px',
      },
  },
  photoContent: {
    gridArea: 'photo',
    width: '70vw',
  },
  thumbnailImage: {
    gridArea: 'photo',
    justifySelf: 'center',
  },
  headerButtonIcon: {
    marginTop: '0px',
    fontSize: '25px',
    color: 'white',
  },
  customizeArea: {
    gridArea: 'buttonArea',
    display: 'grid',
    gridTemplateRows: 'auto 200px',
    justifyContent: 'end',
    height: '70px',
    '@media (max-width: 1000px)': {
      height: '50px',
    },
  },
  customizeButton: {
    marginRight: '22px',
    height: '70px',
    width: '200px',
    fontSize: '12px',
    color: '#fff !important',
    textTransform: 'uppercase',
    background: '#0069af !important',
    borderRadius: '15px',
    border: 'none',
    'letter-spacing': '2px',
    ':hover': {
      background: '#369 !important',
      'letter-spacing': '4px',
      '-webkit-box-shadow': '0px 5px 40px -10px rgba(0,0,0,0.57)',
      '-moz-box-shadow': '0px 5px 40px -10px rgba(0,0,0,0.57)',
      'box-shadow': '5px 40px -10px rgba(0,0,0,0.57)',
      transition: 'all 0.4s ease 0s',
      '@media screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)':
        {
          'letter-spacing': '2px',
        },
    },
    ':disabled': {
      background: '#F2F0F0 !important',
      border: '1px #D6D8DB solid',
      color: '#D6D8DB !important',
    },
    '@media (max-width: 1000px)': {
      height: '50px',
      width: '100px',
      marginRight: '0px',
    },
  },
  schemas: {
    display: 'grid',
    gridArea: 'sider2',
    width: '70vw',
  },
  schema: {
    display: 'grid',
    gridTemplateColumns: '3fr 5fr',
    fontSize: 18,
    fontWeight: 600,
    '@media (max-width: 850px)': {
      fontSize: '12px',
    },
    '@media (max-width: 650px)': {
      fontSize: '10px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '10px',
      },
  },
  builderName: {
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 8,
    '@media (max-width: 850px)': {
      fontSize: '16px',
    },
    '@media (max-width: 650px)': {
      fontSize: '14px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 900px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '14px',
      },
  },
})

const DigitalHomescapesView = ({
  property,
  selection,
  location,
  building,
  index,
}) => {
  if (!building) {
    return null
  }
  const classes = styles()
  const { schemes } = property
  const params = new URLSearchParams(location.search)
  const token = params.get('token')
  const tokenQueryParam = token ? `?token=${token}` : ''
  const propertyUrl = location.pathname.replace('/summary', '')

  const elements = building.views[0].view_elements
    .slice()
    .sort(islistOrderGreater)

  const selectedScheme = getCustomSchemeFromSelection(
    building,
    property,
    selection
  )

  const customColorsForSelectedScheme = getCustomColorsForSelectedScheme(
    building,
    property,
    selection,
    selectedScheme
  )

  const viewMaterialIds =
    building?.views[0]?.view_elements?.map((ve) => ve.material.id) || []

  const renderHeader = () => (
    <div className={classes.header}>
      {selectedScheme !== undefined
        ? selectedScheme.name
        : 'No Scheme Selected'}
    </div>
  )

  const renderImage = () => {
    const { account } = property
    const { views } = building
    const elevation = createElevation(schemes, views[0])
    const thumbnailImage = views[0].thumbnail_src

    return (
      <AutoSizer>
        {({ height, width }) => {
          if (width === 0) {
            return null
          }
          if (!selectedScheme) {
            return (
              <img
                style={{ width, height, objectFit: 'contain' }}
                alt=""
                src={`${BASE_IMAGE_URL}/q_auto/app/${account.cloud_folder_name}/images/${thumbnailImage}`}
              />
            )
          }
          return (
            <ExteriorImage
              engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
              client={account.account_name}
              width={width}
              height={height}
              maxWidth={width}
              rootStyle={{ height }}
              community={property.name}
              plan={building.name}
              elevation={elevation}
              selectedScheme={selectedScheme}
              colorMtd="HYBRID"
              directoryName={account.cloud_folder_name}
              colorSelections={
                customColorsForSelectedScheme
                  ? Object.assign(
                      {},
                      ...Object.entries(customColorsForSelectedScheme)
                        .filter((key) => !Number.isNaN(parseInt(key, 10)))
                        .map(([, value]) => ({
                          [value.materialElementId]: {
                            paletteId: value.materialPaletteId,
                            id: value.id,
                          },
                        }))
                    )
                  : {}
              }
            />
          )
        }}
      </AutoSizer>
    )
  }

  const renderSchemes = () => {
    const filteredSchemesElements = selectedScheme?.scheme_elements.filter(
      (schemeElement) => viewMaterialIds.includes(schemeElement.material.id)
    )

    if (!filteredSchemesElements) {
      return null
    }

    return elements.map((element) => {
      const schemeElement =
        filteredSchemesElements &&
        filteredSchemesElements.find(
          (el) => el.material.element_id === element.material.element_id
        )

      const customColorForSchemeElement =
        customColorsForSelectedScheme &&
        schemeElement &&
        customColorsForSelectedScheme[schemeElement.material.id]
      const color = customColorForSchemeElement
        ? {
            identifier: customColorForSchemeElement.identifier,
            name: customColorForSchemeElement.name,
            identifier_prefix:
              customColorForSchemeElement.color_vendor_product_info
                .product_identifier_prefix,
          }
        : {
            identifier: schemeElement && schemeElement.color.identifier,
            name: schemeElement && schemeElement.color.name,
            identifier_prefix:
              schemeElement &&
              schemeElement.color.color_vendor_product_info
                .product_identifier_prefix,
          }
      return (
        <div
          key={element.list_order + element.material.display_name}
          className={classes.schema}
        >
          <div>
            {`${element.list_order ? element.list_order : ''} ${
              element.material.display_name
            }`}
          </div>
          {color.name && (
            <div>{`${color.identifier_prefix} ${completeColorIdentifier(
              color.identifier
            )} ${color.name}`}</div>
          )}
        </div>
      )
    })
  }

  const renderAbstractPage = () => (
    <div
      className={classes.page}
      key={`${building.name}${[index]}`}
      id={`${building.name}${[index]}`}
    >
      {renderHeader()}
      <div className={classes.photoContent}>{renderImage()}</div>
      <div className={classes.schemas}>
        {selectedScheme && (
          <div className={classes.schema}>
            <div />
            <div className={classes.builderName}>
              {property.account.account_name}
            </div>
          </div>
        )}
        {renderSchemes()}
      </div>
      <div className={classes.customizeArea}>
        <div />
        <Link to={`${propertyUrl}/building/${building.id}${tokenQueryParam}`}>
          <Button
            className={classes.customizeButton}
            disabled={property.is_finalized}
          >
            <EditIcon className={classes.headerButtonIcon} />
          </Button>
        </Link>
      </div>
    </div>
  )

  return <React.Fragment>{renderAbstractPage()}</React.Fragment>
}

DigitalHomescapesView.propTypes = {
  property: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
}

export default DigitalHomescapesView
