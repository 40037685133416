import { makeStyles, Paper, Typography } from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import CheckRoundedIcon from '@material-ui/icons/CheckRounded'
import { completeColorIdentifier, getTextColor } from '../utils/helper'

const styles = makeStyles((theme) => ({
  swatchContainer: {
    margin: '8px 0',
    padding: 5,
    width: '30%',
    height: 70,
    cursor: 'pointer',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      height: 50,
      padding: '5px 5px',
    },
    [theme.breakpoints.up(1921)]: {
      height: 'auto',
      padding: 15,
    },
  },
  textContent: {
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
  colorNameContainer: {
    display: 'flex',
    flexFlow: 'row',
    height: 'max-content',
  },
  selected: {
    fontSize: 15,
    [theme.breakpoints.down('md')]: {
      fontSize: 10,
    },
  },
}))

const Swatch = ({ paletteItem, handleSelectColor, refElement, selected }) => {
  const classes = styles()
  const textColor = getTextColor(paletteItem.color.hex)

  return (
    <Paper
      ref={refElement}
      className={classes.swatchContainer}
      style={{
        backgroundColor: `#${paletteItem.color.hex}`,
        color: `#${textColor}`,
      }}
      onClick={() => handleSelectColor(paletteItem)}
      elevation={selected ? 9 : 0}
    >
      <div className={classes.colorNameContainer}>
        {paletteItem?.color?.color_vendor_product_info
          ?.product_identifier_prefix && (
          <Typography className={classes.textContent} variant="body2">
            {`${
              paletteItem.color.color_vendor_product_info
                .product_identifier_prefix
            } ${completeColorIdentifier(paletteItem.color.identifier)}`}
          </Typography>
        )}
        <Typography
          variant="body2"
          style={{ marginLeft: 'auto', height: 'max-content' }}
        >
          {selected && (
            <CheckRoundedIcon fontSize="large" className={classes.selected} />
          )}
        </Typography>
      </div>
      <Typography className={classes.textContent} variant="body2">
        {paletteItem.color.name}
      </Typography>
    </Paper>
  )
}

Swatch.propTypes = {
  paletteItem: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    palette_id: PropTypes.number,
    color: PropTypes.shape({
      id: PropTypes.number,
      identifier: PropTypes.string,
      name: PropTypes.string,
      hex: PropTypes.string,
      color_vendor_product_info: PropTypes.shape({
        id: PropTypes.number,
        product_group: PropTypes.string,
        product_identifier_prefix: PropTypes.string,
        product_name: PropTypes.string,
      }),
    }),
  }).isRequired,
  handleSelectColor: PropTypes.func.isRequired,
  refElement: PropTypes.func,
  selected: PropTypes.bool.isRequired,
}

Swatch.defaultProps = {
  refElement: undefined,
}

export default Swatch
