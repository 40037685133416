/* eslint-disable react/destructuring-assignment */
import React from 'react'
import PropTypes from 'prop-types'

import flow from 'lodash/fp/flow'

import { makeStyles } from '@material-ui/core'
import { assoc, assocAll } from '../../utils/functions'
import {
  completeColorIdentifier,
  getTextColor,
  imageUrl,
} from '../../utils/helper'

const largeElementCardStyle = {
  float: 'left',
  cursor: 'pointer',
  fontSize: '12px',
  fontWeight: 'bold',
  margin: '3px',
  width: '100px',
  height: '60px',
  border: '1px solid #ddd',
}

const summaryElementCardStyle = {
  fontSize: '12px',
  fontWeight: 'bold',
  width: '130px',
  height: '80px',
  '@media (max-width: 550px)': {
    height: '40px !important',
  },
}

const brochureElementCardStyle = {
  fontSize: '10px',
  fontWeight: 'bold',
  width: '140px',
  height: '80px',
}

const styles = makeStyles({
  checkmark: {
    position: 'absolute',
    bottom: '3px',
    right: '3px',
    zIndex: 10,
  },
  cvSwatch: {
    height: '70px',
    '@media (max-width: 1200px)': {
      height: '40px',
    },
    '@media only screen and (min-device-width: 350px) and (max-device-width: 850px) and (min-device-height: 320px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        height: '25px',
      },
  },
  swatch: {
    height: '80px',
    width: '150px',
    ':hover': {
      border: '1px solid black',
    },
    '@media (max-width: 550px)': {
      height: '50px',
      width: '100px',
    },
    '@media (max-width: 1200px)': {
      height: '50px',
      width: '100px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        height: '50px',
        width: '100px',
      },
  },
  summary: {
    height: '100px',
    width: '160px',
    '@media (max-width: 550px)': {
      height: '50px',
      width: '100px',
    },
    '@media (max-width: 1200px)': {
      height: '50px',
      width: '100px',
      margin: '2px',
    },
    '@media only screen and (min-device-width: 321px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        height: '45px',
        width: '90px',
        lineHeight: '1',
      },
  },
  brochure: {
    height: '50px',
    width: '100px',
    fontSize: '10px',
    fontWeight: '150',
    margin: '5px',
  },
  swatchBody: {
    fontSize: '15px',
    fontWeight: '150',
    '@media (max-width: 1200px)': {
      fontSize: '10px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '10px',
      },
  },
  swatchOverlay: {
    width: '100%',
    height: '100%',
    padding: '4px',
    position: 'relative',
    fontSize: '15px',
    '@media (max-width: 1200px)': {
      fontSize: '10px',
    },
    '@media only screen and (min-device-width: 319px) and (max-device-width: 850px) and (min-device-height: 319px) and (max-device-height: 450px) and (orientation: landscape)':
      {
        fontSize: '10px',
      },
  },
  brochureSwatchOverlay: {
    height: '63%',
  },
  materialNames: {
    marginTop: '2px',
  },
})

const Swatch = ({
  color,
  dirName,
  material,
  materialJsx,
  onClick,
  selected,
  size,
}) => {
  const classes = styles()
  const needsOverlay = () =>
    !color.hex && (size === 'large' || selected || size === 'summary')
  const isColorType = color.colorId !== null
  const textColor = isColorType ? getTextColor(color.hex) : 'fff'

  const style = () => {
    const commonStyle = {
      float: 'left',
    }
    const applyColorAndBackgroundStyle = isColorType
      ? assoc('background', `#${color.hex}`)
      : assocAll({
          textShadow: '0 1px 0 rgba(0, 0, 0, 1)',
          backgroundImage: `url("${imageUrl({
            dirName,
            brickSrc: color.brickId && color.swatch,
            stoneSrc: color.stoneId && color.swatch,
            customOverlaySrc: color.customOverlayId && color.swatch,
            mods: 'w_100',
          })}")`,
        })
    const applySizeSpecificStyle = {
      small: assocAll({
        width: '20%',
        height: '30px',
        textAlign: 'center',
      }),
      large: assocAll(largeElementCardStyle),
      summary: assocAll(summaryElementCardStyle),
      brochure: assocAll(brochureElementCardStyle),
    }

    return flow(
      applyColorAndBackgroundStyle,
      applySizeSpecificStyle[size]
    )(commonStyle)
  }

  const classNameSelector = (pageSize, click) => {
    if (pageSize === 'summary') {
      return classes.summary
    }
    if (pageSize === 'brochure') {
      return classes.brochure
    }
    if (click) {
      return classes.swatch
    }
    return classes.cvSwatch
  }

  const hasIdentifier = (ident, identPrefix) => {
    if (ident === null) {
      return ''
    }
    if (identPrefix !== null) {
      return `${identPrefix} ${completeColorIdentifier(ident)}`
    }
    return `${ident}`
  }

  const materialFontSizer = (materialFont) => {
    if (materialFont.length >= 20) {
      // using a font weight attribute to let the text be editable in pdf. Look at FLAG-1998.
      return (
        <div
          style={{ fontSize: '8px', fontWeight: '700' }}
        >{`${materialFont}\n`}</div>
      )
    }
    // using a font weight attribute to let the text be editable in pdf. Look at FLAG-1998.
    return (
      <div
        style={{ fontSize: '10px', fontWeight: '700' }}
      >{`${materialFont}\n`}</div>
    )
  }

  return (
    <div
      key={color.id}
      style={style()}
      className={classNameSelector(size, onClick)}
      onClick={onClick}
    >
      <div
        className={`${classes.swatchOverlay} ${
          size === 'brochure' ? classes.brochureSwatchOverlay : ''
        }`}
        style={
          needsOverlay()
            ? { backgroundColor: 'rgba(0,0,0,0.3)', color: `#${textColor}` }
            : { color: `#${textColor}` }
        }
      >
        {(size === 'large' || size === 'summary') && (
          <React.Fragment>
            {material}
            <div className={classes.swatchBody}>
              {hasIdentifier(color.identifier, color.identifier_prefix)}
              <div>{color.name}</div>
            </div>
          </React.Fragment>
        )}
        {size === 'brochure' && (
          <React.Fragment>
            <div className={classes.swatchBody}>
              {hasIdentifier(color.identifier, color.identifier_prefix)}
              <div>{color.name}</div>
            </div>
          </React.Fragment>
        )}
      </div>
      <div className={classes.materialNames}>
        {material?.map((currMaterial) => materialFontSizer(currMaterial.name))}
        {materialJsx}
      </div>
    </div>
  )
}

Swatch.propTypes = {
  color: PropTypes.shape({
    id: PropTypes.number,
    identifier: PropTypes.number,
    name: PropTypes.string,
    colorId: PropTypes.number,
    brickId: PropTypes.number,
    stoneId: PropTypes.number,
    customOverlayId: PropTypes.number,
    hex: PropTypes.string,
    swatch: PropTypes.string,
    identifier_prefix: PropTypes.string,
  }).isRequired,
  dirName: PropTypes.string.isRequired,
  material: PropTypes.string,
  materialJsx: PropTypes.object,
  onClick: PropTypes.func,
  selected: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
}

Swatch.defaultProps = {
  material: null,
  materialJsx: null,
  onClick: null,
}

export { Swatch }
