import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  listNumber: {
    backgroundColor: '#fff',
    borderRadius: '50%',
    border: '1px solid #000',
    width: 23,
    height: 23,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 1,
    fontSize: 14,
    fontWeight: 400,
  },
})

export default function ListNumber({ number, customClass, customStyle }) {
  const classes = useStyles()
  return (
    <span
      className={`${classes.listNumber} ${customClass}`}
      style={customStyle}
    >
      {number}
    </span>
  )
}

ListNumber.propTypes = {
  number: PropTypes.oneOf([PropTypes.number, PropTypes.string]).isRequired,
  customClass: PropTypes.object,
  customStyle: PropTypes.object,
}
ListNumber.defaultProps = {
  customClass: {},
  customStyle: {},
}
