import React from 'react'
import ExteriorImage from '@anewgo/exterior-image/dist'
import PropTypes from 'prop-types'
import { makeStyles, Typography } from '@material-ui/core'
import { chunk } from 'lodash'
import { Swatch } from '../../../common'
import { BASE_IMAGE_URL, SHERWIN_WILLIAMS_LOGO } from '../../../constants/Paths'
import {
  createElevation,
  getCustomColorsForSelectedScheme,
  getCustomSchemeFromSelection,
  groupMaterialsByColorForNonCart,
} from '../../../../utils/helper'
import usePrintPageStyle from '../../../../hooks/usePrintPageStyle'
import MaterialList from '../../../floorCovering/MaterialList'
import ListNumber from '../../../floorCovering/ListNumber'

const PAGE_WIDTH = 756 // at 72 dpi 8 1/2"
const PAGE_HEIGHT = 996

const styles = makeStyles({
  portraitPage: {
    width: `${PAGE_WIDTH}px`, // at 72dpi
    height: `${PAGE_HEIGHT}px`,
  },
  landscapePage: {
    width: `${PAGE_HEIGHT}px`, // at 72dpi
    height: `${PAGE_WIDTH}px`,
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    pageBreakAfter: 'always',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  footer: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr .5fr .5fr',
  },
  footerBottomRow: {
    fontWeight: 'bold',
    textAlign: 'center',
    gridRow: '2',
    lineHeight: 0,
  },
  schemeFooter: {
    display: 'grid',
    justifyItems: 'center',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr .2fr',
    minHeight: 180,
  },
  header: {
    gridRow: '1',
    display: 'grid',
    gridTemplateRows: '1fr',
  },
  headerCover: {
    gridColumn: '1',
    marginTop: '10px',
    justifySelf: 'center',
  },
  headerLeft: {
    gridColumn: '1',
  },
  headerRight: {
    gridColumn: '2',
    fontSize: '12px',
  },
  buildingName: {
    float: 'right',
    fontSize: '12px',
    borderRight: '1px solid black',
    paddingRight: '15px',
  },
  schemeName: {
    float: 'right',
    paddingLeft: '25px',
  },
  coverPageContent: {
    flex: '1',
    width: '100%',
    position: 'relative',
    margin: '20px 0',
  },
  exteriorImg: {
    flex: '1',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  insetPhoto: {
    position: 'absolute',
    bottom: '-10px',
    right: 0,
    border: 'solid 3px white',
    borderRadius: '5px',
  },
  materialIndexes: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '3px 2px',
  },
  swatchListContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
})

const SHWLogo = () => (
  <img
    alt="LOGO"
    src={SHERWIN_WILLIAMS_LOGO}
    height={45}
    width={180}
    style={{ marginLeft: '1em' }}
  />
)

const DefaultPage = ({ property, selection, isPortrait, building, index }) => {
  const classes = styles()
  const { schemes } = property
  const selectedScheme = getCustomSchemeFromSelection(
    building,
    property,
    selection
  )
  const customColorsForSelectedScheme = getCustomColorsForSelectedScheme(
    building,
    property,
    selection,
    selectedScheme
  )

  usePrintPageStyle(isPortrait)

  const getColorNumbersData = (materials) => {
    const sortedMaterials = materials.sort((a, b) => {
      return a.pointNumber < b.pointNumber ? -1 : 1
    })

    const minMaterialPointNumber = sortedMaterials?.[0]?.pointNumber

    const materialJsx = (
      <div className={classes.materialIndexes}>
        {sortedMaterials.map((material) => (
          <ListNumber
            number={material.pointNumber}
            key={material.pointNumber}
          />
        ))}
      </div>
    )

    return {
      minMaterialPointNumber,
      materialJsx,
    }
  }

  const renderContentPageHeader = () => (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <SHWLogo style={{ height: '30px' }} />
      </div>
      <div className={classes.headerRight}>
        <div className={classes.rightHeadWrapper}>
          {selectedScheme !== undefined ? (
            <div className={classes.schemeName}>{`${selectedScheme.name}`}</div>
          ) : (
            <div className={classes.schemeName}>No Scheme Selected</div>
          )}
          <div className={classes.buildingName}>{`${building.name}`}</div>
        </div>
      </div>
    </div>
  )

  const hemiRenderedSummaryPage = () => {
    const { account } = property
    const { views } = building
    const elevation = createElevation(schemes, views[0])
    const thumbnailImage = views[0].thumbnail_src
    const directoryName = account.cloud_folder_name

    const img = new Image()
    img.src = `${BASE_IMAGE_URL}/q_auto/app/${directoryName}/images/${thumbnailImage}`
    img.onload = () => {
      document.getElementById(`insetImage${index}`).style.height = `${
        `${img.naturalHeight}` / 5
      }px`
      document.getElementById(`insetImage${index}`).style.maxHeight = '150px'
      document.getElementById(`insetImage${index}`).style.minHeight = '90px'
    }

    const { materials } = groupMaterialsByColorForNonCart(building)

    return selectedScheme ? (
      <div className={classes.exteriorImg}>
        <ExteriorImage
          engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
          client={account.account_name}
          maxWidth={PAGE_WIDTH}
          rootStyle={{ height: '100%', width: '100%' }}
          community={property.name}
          plan={property.buildings[0].name}
          elevation={elevation}
          selectedScheme={selectedScheme}
          colorMtd="HYBRID"
          directoryName={directoryName}
          colorSelections={
            customColorsForSelectedScheme
              ? Object.assign(
                  {},
                  ...Object.entries(customColorsForSelectedScheme)
                    .filter((key) => !Number.isNaN(parseInt(key, 10)))
                    .map(([, value]) => ({
                      [value.materialElementId]: {
                        paletteId: value.materialPaletteId,
                        id: value.id,
                      },
                    }))
                )
              : {}
          }
          descriptionPoints={materials}
        />
        <img
          id={`insetImage${index}`}
          className={classes.insetPhoto}
          alt=""
          src={`${BASE_IMAGE_URL}/q_auto/app/${directoryName}/images/${thumbnailImage}`}
        />
      </div>
    ) : (
      <div className={classes.exteriorImg}>
        <img
          style={{
            maxHeight: '100%',
            maxWidth: '100%',
            margin: 'auto',
          }}
          alt=""
          src={`${BASE_IMAGE_URL}/q_auto/app/${directoryName}/images/${building.views[0].base_image_src}`}
        />
        <img
          id={`insetImage${index}`}
          className={classes.insetPhoto}
          alt=""
          src={`${BASE_IMAGE_URL}/q_auto/app/${directoryName}/images/${thumbnailImage}`}
        />
      </div>
    )
  }

  const renderSchemeFooter = () => {
    if (selectedScheme !== undefined) {
      const { colorMaterialIdsMap: groupedMaterials, materials } =
        groupMaterialsByColorForNonCart(
          building,
          selectedScheme,
          customColorsForSelectedScheme
        )

      const swatchesDataList = []
      Object.keys(groupedMaterials).forEach((colorId) => {
        const colorObject = groupedMaterials[colorId][0]
        const materialList = chunk(groupedMaterials[colorId].slice(1), 5)

        materialList.forEach((material) => {
          if (
            swatchesDataList.some(
              (element) => element.color.hex === colorObject.hex
            )
          ) {
            return
          }
          const colorNumbersData = getColorNumbersData(material)

          swatchesDataList.push({
            key: `${colorObject.id}-${material}`,
            color: {
              id: colorObject.id,
              identifier: parseInt(colorObject.identifier, 10),
              name: colorObject.name,
              colorId: colorObject.id,
              hex: colorObject.hex,
              identifier_prefix:
                colorObject.color_vendor_product_info.product_identifier_prefix,
            },
            minMaterialPointNumber: colorNumbersData.minMaterialPointNumber,
            selected: !!(colorObject && colorObject.id),
            size: 'brochure',
            dirName: property.account.cloud_folder_name,
          })
        })
      })

      swatchesDataList.sort((a, b) => {
        return a.minMaterialPointNumber < b.minMaterialPointNumber ? -1 : 1
      })

      return (
        <>
          <MaterialList elements={materials} />
          <div>
            {swatchesDataList.map((swatchData) => {
              return (
                <Swatch
                  key={swatchData.key}
                  color={swatchData.color}
                  materialJsx={swatchData.materialJsx}
                  selected={swatchData.selected}
                  size={swatchData.size}
                  dirName={swatchData.dirName}
                />
              )
            })}
          </div>
        </>
      )
    }
    return null
  }

  const renderSchemeDisclaimer = () => (
    <Typography variant="caption">
      <b>{property && property.rep ? property.rep.full_name : ''}</b>
      <br />
      <b>
        {property && property.rep
          ? `${property.rep.phone} • ${property.rep.email}`
          : ''}
      </b>
      <br />
      Actual color may vary from on-screen representation. To confirm your color
      choices prior to purchase, please view a physical color chip, color card,
      or painted sample. Sherwin-Williams is not responsible for the content and
      photos shared by users of their color selection tools. The Cover The Earth
      logo and the Sherwin-Williams logo are trademarks owned or licensed by The
      Sherwin-Williams Company. ©{`${new Date().getFullYear()} `}
      The Sherwin-Williams Company.
      {property && property.project_id ? `• ${property.project_id}` : ''}
    </Typography>
  )

  return (
    <React.Fragment>
      <div
        className={`${classes.page} ${
          isPortrait ? classes.portraitPage : classes.landscapePage
        }`}
        key={building.id}
      >
        {renderContentPageHeader()}
        <div className={classes.coverPageContent}>
          {hemiRenderedSummaryPage()}
        </div>
        <div className={classes.schemeFooter}>
          <div>{renderSchemeFooter()}</div>
          <div className={classes.footerBottomRow}>
            {renderSchemeDisclaimer()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

DefaultPage.propTypes = {
  property: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
  isPortrait: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
}

export default DefaultPage
