import React from 'react'
import PropTypes from 'prop-types'
import { AutoSizer } from 'react-virtualized'
import ExteriorImage from '@anewgo/exterior-image'
import { makeStyles } from '@material-ui/core'
import { BASE_IMAGE_URL, SHERWIN_WILLIAMS_LOGO } from '../../../constants/Paths'
import {
  completeColorIdentifier,
  createElevation,
  getCustomColorsForSelectedScheme,
  getCustomSchemeFromSelection,
  islistOrderGreater,
} from '../../../../utils/helper'
import usePrintPageStyle from '../../../../hooks/usePrintPageStyle'

const PAGE_WIDTH = 756 // at 72 dpi 8 1/2"
const PAGE_HEIGHT = 996

const styles = makeStyles({
  portraitPage: {
    width: PAGE_WIDTH,
    height: PAGE_HEIGHT,
  },
  page: {
    display: 'grid',
    gridRowGap: '10px',
    gridTemplateRows: 'auto 1fr auto',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: '"header" "photo" "selectionsFooter"',
    pageBreakAfter: 'always',
    backgroundColor: 'white',
  },
  footerBottomRow: {
    alignItems: 'baseline',
    display: 'grid',
  },
  schemeFooter: {
    display: 'grid',
    gridTemplateRows: '1fr .2fr',
  },
  schemeFooterTopRow: {
    marginLeft: 35,
    marginBottom: 20,
  },
  header: {
    gridRow: '1',
    display: 'grid',
    gridTemplateRows: '1fr',
    width: PAGE_WIDTH,
  },
  headerLeft: {
    gridColumn: '1',
  },
  headerRight: {
    gridColumn: '2',
    fontSize: '20px',
    paddingTop: '5px',
  },
  schemeName: {
    float: 'right',
    paddingLeft: '25px',
  },
  coverPageContent: {
    gridArea: 'photo',
    width: '100%',
  },
  schema: {
    display: 'grid',
    gridTemplateColumns: '3fr 5fr',
    paddingBottom: 5,
  },
  builderName: {
    fontSize: 24,
    fontWeight: 700,
    paddingBottom: 8,
  },
})

const SHWLogo = () => (
  <img
    alt="LOGO"
    src={SHERWIN_WILLIAMS_LOGO}
    height={45}
    width={180}
    style={{ marginLeft: '1em' }}
  />
)

const DigitalHomescapes = ({ property, selection, building }) => {
  usePrintPageStyle(true)

  const classes = styles()
  const { schemes } = property
  const elements = building.views[0].view_elements
    .slice()
    .sort(islistOrderGreater)
  const selectedScheme = getCustomSchemeFromSelection(
    building,
    property,
    selection
  )

  const customColorsForSelectedScheme = getCustomColorsForSelectedScheme(
    building,
    property,
    selection,
    selectedScheme
  )
  const renderContentPageHeader = () => (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <SHWLogo style={{ height: '30px' }} />
      </div>
      <div className={classes.headerRight}>
        <div>
          {selectedScheme !== undefined ? (
            <div className={classes.schemeName}>{`${selectedScheme.name}`}</div>
          ) : (
            <div className={classes.schemeName}>No Scheme Selected</div>
          )}
        </div>
      </div>
    </div>
  )

  const renderSchemeFooter = () => {
    const viewMaterialIds =
      building?.views[0]?.view_elements?.map((ve) => ve.material.id) || []

    const filteredSchemesElements = selectedScheme?.scheme_elements?.filter(
      (schemeElement) => viewMaterialIds.includes(schemeElement.material.id)
    )

    if (!filteredSchemesElements) {
      return null
    }

    return elements.map((element) => {
      const schemeElement =
        filteredSchemesElements &&
        filteredSchemesElements.find(
          (el) => el.material.element_id === element.material.element_id
        )

      const customColorForSchemeElement =
        customColorsForSelectedScheme &&
        schemeElement &&
        customColorsForSelectedScheme[schemeElement.material.id]
      let color = null
      if (customColorForSchemeElement || schemeElement) {
        color = customColorForSchemeElement
          ? {
              identifier: customColorForSchemeElement.identifier,
              name: customColorForSchemeElement.name,
              identifier_prefix:
                customColorForSchemeElement.color_vendor_product_info
                  .product_identifier_prefix,
            }
          : {
              identifier: schemeElement.color.identifier,
              name: schemeElement.color.name,
              identifier_prefix:
                schemeElement.color.color_vendor_product_info
                  .product_identifier_prefix,
            }
      }
      return (
        <div
          key={element.list_order + element.material.display_name}
          className={classes.schema}
        >
          <div>
            {`${element.list_order ? element.list_order : ''} ${
              element.material.display_name
            }`}
          </div>
          {color && (
            <div>{`${color.identifier_prefix} ${completeColorIdentifier(
              color.identifier
            )} ${color.name}`}</div>
          )}
        </div>
      )
    })
  }
  const renderDisclaimer = () => (
    <div style={{ fontSize: 9 }}>
      Actual color may vary from on-screen representation. To confirm your color
      choices prior to purchase, please view a physical color chip, color card,
      or painted sample. Sherwin-Williams is not responsible for the content and
      photos shared by users of their color selection tools. The Cover The Earth
      logo and the Sherwin-Williams logo are trademarks owned or licensed by The
      Sherwin-Williams Company. ©{`${new Date().getFullYear()} `}
      The Sherwin-Williams Company.
    </div>
  )

  const renderSchemeDisclaimer = () => {
    const builderLogoSrc = `${BASE_IMAGE_URL}/app/${property.account.cloud_folder_name}/assets/custom/${property.account.account_logo}`
    return (
      <div className={classes.footerBottomRow}>
        <img
          style={{
            maxWidth: 220,
            objectFit: 'contain',
            justifySelf: 'end',
          }}
          alt=""
          src={builderLogoSrc}
        />
        {renderDisclaimer()}
      </div>
    )
  }

  const renderImage = () => {
    const { account } = property
    const { views } = building
    const elevation = createElevation(schemes, views[0])
    const thumbnailImage = views[0].thumbnail_src

    return (
      <AutoSizer>
        {({ height, width }) => {
          if (width === 0) {
            return null
          }
          if (!selectedScheme) {
            return (
              <img
                style={{ width, height, objectFit: 'contain' }}
                alt=""
                src={`${BASE_IMAGE_URL}/q_auto/app/${account.cloud_folder_name}/images/${thumbnailImage}`}
              />
            )
          }
          return (
            <ExteriorImage
              engineUrl={`${process.env.HEMI_SERVER_PREFIX}/api/cv`}
              client={account.account_name}
              width={width}
              height={height}
              maxWidth={width}
              rootStyle={{ height }}
              community={property.name}
              plan={building.name}
              elevation={elevation}
              selectedScheme={selectedScheme}
              colorMtd="HYBRID"
              directoryName={account.cloud_folder_name}
              colorSelections={
                customColorsForSelectedScheme
                  ? Object.assign(
                      {},
                      ...Object.entries(customColorsForSelectedScheme)
                        .filter((key) => !Number.isNaN(parseInt(key, 10)))
                        .map(([, value]) => ({
                          [value.materialElementId]: {
                            paletteId: value.materialPaletteId,
                            id: value.id,
                          },
                        }))
                    )
                  : {}
              }
            />
          )
        }}
      </AutoSizer>
    )
  }

  const renderBrochure = () => (
    <React.Fragment>
      <div
        className={`${classes.portraitPage} ${classes.page}`}
        key={building.id}
      >
        {renderContentPageHeader(selectedScheme)}
        <div className={classes.coverPageContent}>{renderImage(building)}</div>
        <div className={classes.schemeFooter}>
          <div className={classes.schemeFooterTopRow}>
            {selectedScheme && (
              <div className={classes.schema}>
                <div />
                <div className={classes.builderName}>
                  {property.account.account_name}
                </div>
              </div>
            )}
            {renderSchemeFooter(building, selectedScheme)}
          </div>
          <div className={classes.footerBottomRow}>
            {renderSchemeDisclaimer()}
          </div>
        </div>
      </div>
    </React.Fragment>
  )

  return <React.Fragment>{renderBrochure()}</React.Fragment>
}

DigitalHomescapes.propTypes = {
  property: PropTypes.object.isRequired,
  selection: PropTypes.object.isRequired,
  building: PropTypes.object.isRequired,
}

export default DigitalHomescapes
