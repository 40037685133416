import { withApollo } from '@apollo/client/react/hoc'
import {
  compose,
  mapProps,
  branch,
  renderComponent,
  renderNothing,
} from 'recompose'
import flow from 'lodash/fp/flow'

import { Loading } from '../components/common/Loading'
import { generateUrl } from './urlGenerator'

import {
  getProp,
  assoc,
  assocAll,
  overrideProp,
  computeProp,
  dissoc,
  assocProp,
  dissocProp,
  appendToProp,
  rejectFromProp,
  pick,
  wrap,
  setIf,
  propIsTruthy,
} from './functions'
import { materialCustomSorter } from './materialCustomSorter'

const downloadFile = (file) => {
  // IE doesn't allow using a blob object directly as link href
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(file)
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL contatining the file.
  const { URL } = window
  const data = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = data
  link.download = file.name
  link.click()

  // For Firefox it is necessary to delay revoking the ObjectURL
  setTimeout(() => URL.revokeObjectURL(data), 100)
}

/* eslint-disable no-bitwise */
const calcLuminance = (hex) => {
  const color = parseInt(hex, 16)
  const r = ((color & 0xff0000) >> 16) / 255
  const g = ((color & 0x00ff00) >> 8) / 255
  const b = (color & 0x0000ff) / 255
  const rs = r <= 0.03928 ? r / 12.92 : (r + 0.055 / 1.055) ** 2.4
  const gs = g <= 0.03928 ? g / 12.92 : (g + 0.055 / 1.055) ** 2.4
  const bs = b <= 0.03928 ? b / 12.92 : (b + 0.055 / 1.055) ** 2.4
  return 0.2126 * rs + 0.7152 * gs + 0.0722 * bs
}

// returns either white or black for a given color
// this function can be used to return a suitable text color to use given an
// input of a background color
const getTextColor = (hex) => {
  const lum = calcLuminance(hex)
  return lum > 0.7 ? '000000' : 'ffffff'
}

const getMaterialDisplayName = (material) =>
  material.displayName !== material.name
    ? `${material.displayName} (${material.name})`
    : `${material.name}`

const grammaticList = (array) =>
  array
    .map((item, i) =>
      array.length > 1 && i === array.length - 1 ? `and ${item}` : item
    )
    .join(array.length > 2 ? ', ' : ' ')

const imageUrl = ({ dirName, brickSrc, stoneSrc, customOverlaySrc, mods }) => {
  const noClientName = null
  if (brickSrc) {
    return `${generateUrl(noClientName, 'texturePhotosBrick', mods)}${brickSrc}`
  }
  if (stoneSrc) {
    return `${generateUrl(noClientName, 'texturePhotosStone', mods)}${stoneSrc}`
  }
  if (customOverlaySrc) {
    return `${generateUrl(dirName, 'image', mods)}${customOverlaySrc}`
  }

  return null
}

//
// HOC
//

/**
 * graphqlFunctionProp - HOC that adds a query prop as a function, not as data,
 * much like the graphql function deals with mutations.
 */
const graphqlFunctionProp = (query, propName, mapPropsToVariables) =>
  compose(
    withApollo,
    mapProps(
      computeProp(propName, (props) =>
        flow(getProp('client'), (client) =>
          flow(
            (vars) => vars || {},
            (vars) =>
              mapPropsToVariables
                ? assocAll(mapPropsToVariables(props), vars)
                : vars,
            wrap('variables'),
            assoc('query', query),
            client.query
          )
        )(props)
      )
    )
  )

/**
 * graphqlProps - shorthand for filling out the props option within a graphql
 * function call.
 */
const graphqlProps =
  (dataKey, mapDataToProps) =>
  ({ data }) => {
    if (data.loading) return { loading: true }
    if (!data[dataKey]) return { error: true }

    return mapDataToProps ? mapDataToProps(data[dataKey]) : pick(dataKey)(data)
  }

const spinnerWhileLoading = branch(
  propIsTruthy('loading'),
  renderComponent(Loading)
)

const skipIf = (condition) => branch(condition, renderNothing)

//
// Palette data recombination
//

/**
 * In case palettes have multiple materials, we want to show a row for each
 * material, not just each palette. In this function we transform the palettes
 * array from props, which has the following structure:
 *
 * - ...palette info
 * - materials (id, name, displayName, identifier, blendMode)
 * - selections (id, hex, swatch, color/brick/stone/overlayId, etc.)
 *
 * ...into this structure (which has an entry for each material WITHIN each
 * palette):
 *
 * - id
 * - name
 * - displayName
 * - identifier
 * - blendMode
 * - palette:
 * --- ...palette info
 * --- selections (id, hex, swatch, color/brick/stone/overlayId, etc.)
 */
const materialPalettes = (palettes) =>
  palettes.reduce((last, palette) => {
    const trimmedPalette = dissoc('materials', palette)
    const newMaterialPalettes = palette.materials.map(
      assoc('palette', trimmedPalette)
    )
    return [...last, ...newMaterialPalettes]
  }, [])

//
// Massaging brick/stone/overlay data
//

const textureFromPaletteSelection = ({
  swatch,
  name,
  brickId,
  stoneId,
  customOverlayId,
}) => ({
  id: brickId || stoneId || customOverlayId,
  library: (brickId && 'BRICK') || (stoneId && 'STONE'),
  src: swatch,
  color: name,
})

const textureIdsFromTexture = ({ library, id }) => ({
  brickId: library === 'BRICK' ? id : null,
  stoneId: library === 'STONE' ? id : null,
  customOverlayId: !library ? id : null,
})

// Formats materials and custom colors selected for them into format
// accepted by ExterionImage colorSelections component parameter.
const formatCustomMaterialsColors = (customScheme) =>
  customScheme
    ? Object.assign(
        {},
        ...customScheme.materials.map(
          (value) =>
            value.elementId && {
              [value.elementId]: {
                paletteId: value.colorPaletteId,
                id: value.colorId,
              },
            }
        )
      )
    : {}

// Creates elevation object supplied to ExteriorImage elevation property
const createElevation = (schemes, view) => {
  const elevation = { schemes: [...schemes], elevation: view }
  elevation.base = view.base_image_src
  elevation.caption = view.name
  elevation.id = view.id

  return elevation
}

// Returns currently selected custom scheme from state provider
const getCustomScheme = (currentDesign) =>
  currentDesign &&
  currentDesign.customSchemes &&
  currentDesign.customSchemes.filter(
    (scheme) => scheme.schemeId === currentDesign.schemeId
  )[0]

const getSessionTokenFromUrl = () => {
  const params = new URLSearchParams(window.location.search)
  const token = params.get('token')
  return token ? `?token=${token}` : ''
}

// Filter customSchemes from current design to only include
// currently selected scheme customization
const formatCurrentDesignToCartItemFormat = (currentDesign) => {
  const customSchemeForCart =
    currentDesign?.customSchemes?.length > 0
      ? currentDesign.customSchemes.filter(
          (customScheme) => customScheme.schemeId === currentDesign.schemeId
        )
      : null
  const formated = {
    ...currentDesign,
    customSchemes: customSchemeForCart?.length < 1 ? null : customSchemeForCart,
  }
  return formated
}

const getMaterialName = (
  viewElement,
  customSchemeMaterial,
  schemeMaterial
) => `${viewElement.list_order ? `${viewElement.list_order}. ` : ''}
  ${
    customSchemeMaterial?.materialName
      ? customSchemeMaterial.materialName
      : schemeMaterial?.material?.display_name
  }`

const completeColorIdentifier = (color) => {
  if (typeof color === 'number') {
    if (color < 1000 && color >= 100) {
      return `0${color}`
    }
    if (color < 100 && color >= 10) {
      return `00${color}`
    }
    if (color < 10 && color >= 1) {
      return `000${color}`
    }
    return color
  }
  if (color.length > 4) {
    return completeColorIdentifier(parseInt(color, 10))
  }
  return color
}

const getColorIdentier = (customSchemeMaterial, schemeMaterial) => {
  // eslint-disable-next-line no-nested-ternary
  return customSchemeMaterial?.vendorPrefix
    ? `${customSchemeMaterial?.vendorPrefix} ${customSchemeMaterial?.colorIdentifier}`
    : schemeMaterial?.color?.color_vendor_product_info
        ?.product_identifier_prefix
    ? `${
        schemeMaterial?.color?.color_vendor_product_info
          ?.product_identifier_prefix
      } ${completeColorIdentifier(schemeMaterial?.color?.identifier)}`
    : ''
}
const getColorName = (customSchemeMaterial, schemeMaterial) =>
  `${
    customSchemeMaterial?.colorName
      ? customSchemeMaterial.colorName
      : schemeMaterial?.color?.name
  }`

const validateEmail = (email) => {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email))
}

const validateSherwinOrAnewgoEmail = (email) => {
  /* eslint-disable no-useless-escape */
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(sherwin.com|anewgo.com)$/
  return re.test(String(email))
}

const getImageThumbnail = (account, property, building) => {
  const buildingThumbnail = building?.views[0]?.thumbnail_src
  const propertyThumbnail = property?.buildings[0]?.views[0]?.thumbnail_src

  if (!building && !property) {
    return account?.account_logo
      ? `assets/custom/${account?.account_logo}`
      : null
  }
  if (buildingThumbnail) {
    return `images/${buildingThumbnail}`
  }
  if (propertyThumbnail) {
    return `images/${propertyThumbnail}`
  }

  if (property?.rooms[0]?.image) {
    return `rooms/${property?.rooms[0]?.image}`
  }

  return null
}

// Extracts custom scheme from selection provider
const getCustomSchemeFromSelection = (building, property, selection) => {
  const { schemes } = property
  return (
    property.id &&
    building.id &&
    selection.propertyBuildingScheme[property.id] &&
    selection?.propertyBuildingScheme[property.id][building.id]
      ?.currentSchemeId &&
    schemes.find(
      (scheme) =>
        scheme.id ===
        selection.propertyBuildingScheme[property.id][building.id]
          .currentSchemeId
    )
  )
}

const islistOrderGreater = (element1, element2) => {
  if (element1.list_order && element2.list_order) {
    return element1.list_order > element2.list_order ? 1 : -1
  }
  if (element1.list_order && !element2.list_order) {
    return -1
  }
  if (element2.list_order && !element1.list_order) {
    return 1
  }

  return element1.material.display_name.localeCompare(
    element2.material.display_name,
    undefined,
    { numeric: true, sensitivity: 'base' }
  )
}

/**
 * We need to detect duplicate material names for one scheme
 */
const getSchemesMaterials = (sessionState, selectedBuildings) => {
  const sortByName = (itemA, itemB, key) => {
    const valueA = key ? itemA[key] : itemA
    const valueB = key ? itemB[key] : itemB
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase())
  }

  // We need to merge custom materials with materials which were inserted from CV Dashboard
  const schemesWithMergedMaterials = sessionState.cart?.map((cartItem) => {
    // We need to find all materials for one scheme
    // That's why we need to go through building => views => view_elements => material
    const filteredBuilding = selectedBuildings.find(
      (building) => building.id === cartItem.viewId
    )

    const customMaterials = cartItem?.customSchemes?.[0]?.materials
    const view = filteredBuilding.views[0]

    const duplicityObject = {}
    const duplicateMaterials = []

    for (const viewElement of view.view_elements) {
      // Find if material was rewritten by custom material
      const customMaterial = customMaterials?.find(
        (material) => viewElement.material.id === material.materialId
      )

      // Custom material rewrite old material
      const key =
        customMaterial?.materialName || viewElement.material.display_name

      // If key already exist push key to duplicateMaterials
      // If key does not exist increment value with certain key
      if (duplicityObject[key] === 1) {
        duplicateMaterials.push(key)
        duplicityObject[key] += 1
      } else {
        duplicityObject[key] = 1
      }
    }

    return {
      schemeName: cartItem.schemeName,
      duplicateMaterials: duplicateMaterials.sort(sortByName),
    }
  })

  const schemesWithDuplicateMaterials = schemesWithMergedMaterials
    // If there is more than 1 material there are duplicities
    .filter((scheme) => scheme.duplicateMaterials.length > 0)
    // Sort by schemeName
    .sort((a, b) => sortByName(a, b, 'schemeName'))

  return schemesWithDuplicateMaterials
}

const groupMaterialsByColorForNonCart = (
  building,
  selectedScheme,
  customScheme
) => {
  const colorMaterialIdsMap = {}
  const usedMaterials = new Set()
  const materialIdToNames = {}

  materialCustomSorter(
    building.views[0].view_elements,
    'material.display_name'
  ).forEach((element, index) => {
    materialIdToNames[element.material.id] = {
      name: element.material.display_name,
      pointNumber: index + 1,
      materialId: element.material.element_id,
    }
  })

  // Maps customScheme if any to an object with key = color id and value = list where
  // 1st element is colorObject and other elements are materials Ids
  if (customScheme) {
    Object.keys(customScheme)
      .filter((id) => id !== 'schemeId')
      .forEach((materialId) => {
        const colorObject = customScheme[materialId]
        if (colorMaterialIdsMap[customScheme[materialId].id]) {
          colorMaterialIdsMap[customScheme[materialId].id].push(
            materialIdToNames[materialId.toString()]
          )
          if (colorObject.hex) {
            materialIdToNames[materialId.toString()].hex = colorObject.hex
          }
        } else {
          colorMaterialIdsMap[customScheme[materialId].id] = [
            customScheme[materialId],
          ]
          colorMaterialIdsMap[customScheme[materialId].id].push(
            materialIdToNames[materialId.toString()]
          )
          if (colorObject.hex) {
            materialIdToNames[materialId.toString()].hex = colorObject.hex
          }
        }
        usedMaterials.add(materialId)
      })
  }

  if (selectedScheme && selectedScheme.scheme_elements) {
    selectedScheme.scheme_elements
      .filter(
        (element) =>
          Object.keys(materialIdToNames).includes(
            element.material.id.toString()
          ) && !usedMaterials.has(element.material.id.toString())
      )
      .forEach((schElement) => {
        const colorObject = schElement.color
        if (colorMaterialIdsMap[colorObject.id]) {
          colorMaterialIdsMap[colorObject.id].push(
            materialIdToNames[schElement.material.id]
          )
          if (colorObject.hex) {
            materialIdToNames[schElement.material.id].hex = colorObject.hex
          }
        } else {
          colorMaterialIdsMap[colorObject.id] = [colorObject]
          colorMaterialIdsMap[colorObject.id].push(
            materialIdToNames[schElement.material.id]
          )
          if (colorObject.hex) {
            materialIdToNames[schElement.material.id].hex = colorObject.hex
          }
        }
      })
  }

  return {
    materials: Object.values(materialIdToNames).sort((a, b) =>
      a.pointNumber > b.pointNumber ? 1 : -1
    ),
    colorMaterialIdsMap,
  }
}

// Extracts custom colors for given selected scheme from
// selection provider
const getCustomColorsForSelectedScheme = (
  building,
  property,
  selection,
  selectedScheme
) =>
  property.id &&
  building.id &&
  selectedScheme?.id &&
  selection.propertyBuildingScheme[property.id] &&
  selection.propertyBuildingScheme[property.id][building.id] &&
  selection.propertyBuildingScheme[property.id][building.id]
    .schemeMaterialColor &&
  selection.propertyBuildingScheme[property.id][building.id]
    .schemeMaterialColor[selectedScheme.id]

// Mapper for customScheme MaterialCard component paramether,
// formats custom colors from selection provider to format
// used in MaterialCard
const mapCustomSchemeToMaterialCardFormat = (
  selectedScheme,
  customColorsForSelectedScheme
) => {
  const materials = []
  if (customColorsForSelectedScheme) {
    Object.keys(customColorsForSelectedScheme).forEach((a) => {
      if (a !== 'schemeId') {
        const obj = customColorsForSelectedScheme[a]
        materials.push({
          color: obj.hex,
          colorId: obj.id,
          colorIdentifier: obj.identifier,
          colorName: obj.name,
          colorPaletteId: obj.materialPaletteId,
          elementId: obj.materialElementId,
          materialId: obj.materialId,
          vendorPrefix: obj.color_vendor_product_info.product_identifier_prefix,
        })
      }
    })
  }

  return { schemeId: selectedScheme?.id, materials }
}

export {
  getProp,
  assoc,
  assocAll,
  overrideProp,
  computeProp,
  dissoc,
  assocProp,
  dissocProp,
  appendToProp,
  rejectFromProp,
  pick,
  setIf,
  getTextColor,
  getMaterialDisplayName,
  imageUrl,
  downloadFile,
  grammaticList,
  graphqlFunctionProp,
  graphqlProps,
  spinnerWhileLoading,
  skipIf,
  materialPalettes,
  textureFromPaletteSelection,
  textureIdsFromTexture,
  formatCustomMaterialsColors,
  createElevation,
  getSessionTokenFromUrl,
  getCustomScheme,
  formatCurrentDesignToCartItemFormat,
  getMaterialName,
  islistOrderGreater,
  getColorIdentier,
  getColorName,
  validateEmail,
  getImageThumbnail,
  getCustomSchemeFromSelection,
  getCustomColorsForSelectedScheme,
  groupMaterialsByColorForNonCart,
  mapCustomSchemeToMaterialCardFormat,
  validateSherwinOrAnewgoEmail,
  getSchemesMaterials,
  completeColorIdentifier,
}
